import {Injectable} from '@angular/core'
import {Action, State, StateContext} from '@ngxs/store'

import {BreadcrumbModel} from '@web/core/models/local/breadcrumb.model'
import {HideBreadcrumbs, UpdateBreadcrumbs} from '@web/core/states/breadcrumbs/actions'

export interface BreadcrumbsStateModel {
  items: BreadcrumbModel[];
  hidden: boolean;
  hideBottomLine: boolean;
  hideLangToggler: boolean;
}

const defaults: BreadcrumbsStateModel = {
  items: [],
  hidden: false,
  hideBottomLine: false,
  hideLangToggler: true,
}

@State<BreadcrumbsStateModel>({
  name: 'breadcrumbs',
  defaults,
})
@Injectable()
export class BreadcrumbsState {

  @Action(UpdateBreadcrumbs)
  UpdateBreadcrumbs({patchState, getState}: StateContext<BreadcrumbsStateModel>, {options}: UpdateBreadcrumbs) {
    const {
      items = [],
      hidden = false,
      hideBottomLine = false,
      hideLangToggler = true,
    } = options

    patchState({
      items,
      hidden,
      hideBottomLine,
      hideLangToggler,
    })
  }

  @Action(HideBreadcrumbs)
  HideBreadcrumbs({patchState}: StateContext<BreadcrumbsStateModel>) {
    patchState({hidden: true})
  }
}
